<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          ref="contractIdInput"
          v-model="contractIdInput"
          hint="Vertragsnummer(n) eingeben, Komma separiert"
          label="Vertragsnummer(n)"
          solo
          clearable
          :disabled="loading"
          :rules="contractIdInputRules"
          @change="handleInputChange"
        ></v-text-field>
      </v-col>
    </v-row>
    <template v-for="(contractId, index) in contractIds">
      <v-row :key="index">
        <v-col cols="3">
          <a @click="removeContractId(index)">
            <v-icon
              class="d-inline"
              small
            >
              mdi-delete
            </v-icon>
          </a>
          <span class="ml-2">{{ contractId.id }}</span>
        </v-col>
        <v-col>
          <template v-if="contractId.requested">
            <p v-if="contractId.success === false">
              <v-icon
                color="error"
                class="mr-2"
              >
                mdi-close
              </v-icon>
              Vertrag konnte nicht eingephast werden.
              <template v-if="Array.isArray(contractId.errorMessage)">
                Mögliche Gründe:
                <ul class="ml-7">
                  <li
                    v-for="(error, i) of contractId.errorMessage"
                    :key="i"
                  >
                    {{ error }}
                  </li>
                </ul>
              </template>
              <template v-else>
                <ul class="ml-7">
                  <li>{{ contractId.errorMessage }}</li>
                </ul>
              </template>
            </p>
            <template v-if="contractId.success === true">
              <v-icon
                color="success"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <template v-if="isCamundaAdmin">
                <a
                  :href="contractId.url"
                  target="_blank"
                >Camunda Prozess</a>
              </template>
              <template v-else>
                <span>Prozess erfolgreich gestartet</span>
              </template>
            </template>
          </template>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col
        cols="auto"
        class="mr-auto"
      ></v-col>
      <v-col cols="auto">
        <v-btn
          color="secondary"
          :disabled="contractIds.length < 1"
          :loading="loading"
          @click="startPhaseIn"
        >
          Verträge einphasen
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ContractPhaseIn',
  components: {},
  props: {},
  data: () => ({
    contractIds: [],
    contractIdInput: '',
    contractIdInputRules: [
      (v) =>
        v.length === 0 ||
        /^([0-9]{6}\s*[,;]?\s*)+$/.test(v) ||
        'Vertragsnummer(n) oder Trennzeichen nicht valide.'
    ],
    loading: false
  }),
  computed: {
    camundaUrl: function () {
      const { protocol, hostname } = document.location;
      return `${protocol}//${hostname}/camunda/app/cockpit/default/#/process-instance/`;
    },
    isCamundaAdmin: function () {
      return this.$auth.check('camunda-admin');
    }
  },
  methods: {
    handleInputChange: function () {
      if (!this.$refs.contractIdInput.validate()) return;
      if (this.contractIdInput.length < 1) return;
      const contractIds = this.contractIdInput.split(/[,;]/g).map((id) => {
        return { id: parseInt(id.trim()), requested: false, success: null };
      });

      for (const contractId of contractIds) {
        const exist =
          this.contractIds.filter((c) => c.id === contractId.id).length > 0;
        if (!exist) this.contractIds.push(contractId);
      }

      this.contractIdInput = '';
    },
    removeContractId: function (index) {
      this.contractIds.splice(index, 1);
    },
    getCamundaUrl: function (processId) {
      return `${this.camundaUrl}${processId}/runtime`;
    },
    startPhaseIn: function () {
      if (this.loading) return;

      this.loading = true;
      this.$emit('isLoading', true);

      const requestIds = [];
      for (const id of this.contractIds) {
        if (id.success && id.requested) continue;

        id.requested = true;
        requestIds.push(id.id);
      }

      HTTP.post('order/startLegacy', requestIds)
        .then((res) => {
          if (!res.status === 200) {
            this.showToast(
              `Das einphasen der Verträge war nicht erfolgreich: ${res.status}`
            );
            return;
          }

          for (const id of this.contractIds) {
            if (id.success) continue;
            if (Object.hasOwnProperty.call(res.data, id.id)) {
              Object.keys(res.data[id.id]).forEach((i) => {
                id[i] = res.data[id.id][i];
              });
              id.url = this.getCamundaUrl(id.processId);
            } else {
              id.success = false;
              id.errorMessage = [
                'An dem Vertrag ist keine passende Installationsadresse vorhanden',
                'Vertrag existiert nicht',
                'Vertrag ist bereits aktiv oder wurde deaktiviert'
              ];
            }
          }

          const successCount = Object.keys(res.data).filter(
            (i) => res.data[i].success
          );

          if (successCount < 1) {
            this.showToast(
              'Es konnte kein Vertrag eingephast werden',
              'warning'
            );
            return;
          }

          this.showToast(
            [
              'Das einphasen der Verträge war erfolgreich',
              `${successCount} Verträge eingephast`
            ],
            'success'
          );
        })
        .catch((err) => {
          this.showToast(`Fehler beim einphasen der Verträge - ${err}`);
        })
        .finally(() => {
          this.loading = false;
          this.$emit('isLoading', false);
        });
    },
    showToast: function (message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>
